import React from "react";

import "react-select/dist/react-select.css";
import Swiper from "swiper";
import { isMobile } from "@shared/scripts/utils/tool";
import HomeCenterCard from "./HomeCenterCard";
import arrowImg from "right-arrow.png";
import circleArrowImg from "circle-right-arrow.png";
import ArrowHoverImg from "circle-right-arrow-hover.png";
import abilityImg from "menu-ability.png";

class SotaXt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      xtList: [],
      count: 0,
      xtLoading: true,
      hoveredIndex: null,
    };
    // this.ref = useRef(null);
    this.elementRef = React.createRef();
  }

  componentDidMount() {
    this.fetchXtList();
  }

  getTop = () => {
    const child = this.elementRef.current;
    const parent = document.querySelector(".home__center-right__list");
    const childRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    const topRelativeToParent = childRect.top - parentRect.top;
    return topRelativeToParent;
  };

  getHeight = () => {
    if (this.elementRef.current) {
      const { height } = this.elementRef.current.getBoundingClientRect();
      return height;
    }
    return 0;
  };

  fetchXtList() {
    // const url = "api/v3/sota/chat_sets/sidebar";
    $.ajax({
      url: "https://sota.jiqizhixin.com/api/v3/sota/chat_sets/list",
      // url: window.location.hostname.includes("localhost")
      //   ? `https://sota.almosthuman.cn/${url}`
      //   : `${window.location.origin}/${url}`,
      method: "GET",
      datType: "json",
      success: response => {
        this.setState({
          xtList:
            response.chatSets && response.chatSets.length > 0
              ? response.chatSets.slice(0, 10)
              : [], // 设置数据
          count: response.totalCount || 0,
          xtLoading: false, // 设置加载状态为 false
        });
        if (!this.state.xtLoading && this.state.xtList.length > 0) {
          new Swiper("#js-sota-xt", {
            autoplay: true,
            // autoplay: {
            //   delay: 3000,
            //   stopOnLastSlide: false,
            //   disableOnInteraction: true,
            // },
            loop: true,
            pagination: {
              el: ".swiper-pagination",
            },
          });
        }
      },
      error: (xhr, status, error) => {
        console.log("error", error);
        this.setState({
          xtLoading: false, // 设置加载状态为 false
        });
      },
    });
  }

  jumpToXt() {
    window.open(`https://sota.jiqizhixin.com/xt-terminal`, "_blank");
  }

  render() {
    const { xtList, count, hoveredIndex } = this.state;
    return (
      xtList &&
      xtList.length > 0 && (
        <>
          <div className="home__center-right-header">
            <div className="home__center-right-title">
              <div>尝试最新 AI 模型</div>
              <p>
                共接入 <span>{count}</span> 个模型，选择一个开始使用
              </p>
            </div>
            <div className="home__center-more" onClick={() => this.jumpToXt()}>
              <img
                className="home__center-more__arrow"
                src={circleArrowImg}
                alt="arrow"
              />
              <img
                className="home__center-more__hover"
                src={ArrowHoverImg}
                alt="arrow"
              />
            </div>
          </div>
          {isMobile() ? (
            <>
              <div className="swiper home__center-right__list" id="js-sota-xt">
                <div className="swiper-wrapper">
                  {xtList.map(item => (
                    <div
                      className="swiper-slide home__xt-item-wrapper"
                      key={item.id}
                      onClick={() =>
                        window.open(
                          `https://sota.jiqizhixin.com/xt-terminal/${item.id}`,
                          "_blank"
                        )
                      }
                    >
                      <div className="home__xt-item">
                        <div className="home__xt-item__top">
                          <div className="home__xt-item__title">
                            <img src={abilityImg} alt="ability" />
                            <p className="over--1">{item.name}</p>
                          </div>
                          {!!item.sharedSessionsCount && (
                            // 该频道下状态为“公开”的测例总数
                            <div className="home__xt-item__count">
                              {item.sharedSessionsCount} 条实测
                            </div>
                          )}
                        </div>
                        <div className="home__xt-item__desc over--2">
                          {item.summary}
                        </div>
                        <div className="home__xt-item__bottom">
                          {/* ready && running显示运行中 */}
                          <div className="home__xt-item__open">
                            {item.ready && item.running && (
                              <div className="home__xt-item__open-state">
                                <span className="home__xt-item__open-dot" />
                                运行中
                              </div>
                            )}
                          </div>
                          {item.partner && (
                            <div
                              className="home__xt-item__from"
                              onClick={e => {
                                e.stopPropagation();
                                item.partner.website &&
                                  window.open(item.partner.website, "_black");
                              }}
                            >
                              <img
                                src={item.partner.logoUrl}
                                alt={item.partner.name}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="swiper-pagination" />
              </div>
            </>
          ) : (
            <div className="home__center-right__list">
              <div className="home__center-right__hover" />
              {xtList.map((item, index) => (
                <HomeCenterCard
                  key={item.id}
                  hoveredIndex={hoveredIndex}
                  type="right"
                  index={index}
                  initTop={8}
                >
                  <div
                    className="home__xt-item"
                    key={index}
                    ref={this.elementRef}
                    onMouseEnter={() => this.setState({ hoveredIndex: index })}
                    onMouseLeave={() => this.setState({ hoveredIndex: null })}
                    onClick={() => {
                      window.open(
                        `https://sota.jiqizhixin.com/xt-terminal/${item.id}`,
                        "_blank"
                      );
                    }}
                  >
                    <div className="home__xt-item__top">
                      <div className="home__xt-item__title">
                        <img src={abilityImg} alt="ability" />
                        <p className="over--1">{item.name}</p>
                      </div>
                      {!!item.sharedSessionsCount && (
                        <div className="home__xt-item__count">
                          {item.sharedSessionsCount} 条实测
                        </div>
                      )}
                    </div>
                    <div className="home__xt-item__desc over--2">
                      {item.summary}
                    </div>
                    <div className="home__xt-item__bottom">
                      {/* ready && running显示运行中 */}
                      <div className="home__xt-item__open">
                        {item.ready && item.running && (
                          <div className="home__xt-item__open-state">
                            <span className="home__xt-item__open-dot" />
                            运行中
                          </div>
                        )}
                      </div>
                      {item.partner && (
                        <div
                          className="home__xt-item__from"
                          onClick={e => {
                            e.stopPropagation();
                            item.partner.website &&
                              window.open(item.partner.website, "_black");
                          }}
                        >
                          <img
                            src={item.partner.logoUrl}
                            alt={item.partner.name}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </HomeCenterCard>
              ))}
            </div>
          )}

          <div
            className="home__center-right__more"
            onClick={() => this.jumpToXt()}
          >
            进入小土终端，在线实测更多模型
            <img src={arrowImg} alt="arrow" />
          </div>
        </>
      )
    );
  }
}

export default SotaXt;
