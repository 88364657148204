import banner from "@shared/scripts/features/banner";

const home = () => {
  banner();

  // const tabs = document.querySelectorAll(".home-right__tab");
  // tabs.forEach(tab => {
  //   tab.addEventListener("click", function() {
  //     // tab bar
  //     tabs.forEach(t => {
  //       t.classList.remove("home-right__tab--active");
  //       t.querySelector(".tab-icon").style.display = "block";
  //       t.querySelector(".tab-icon--active").style.display = "none";
  //     });
  //     tab.querySelector(".tab-icon").style.display = "none";
  //     tab.querySelector(".tab-icon--active").style.display = "block";
  //     this.classList.add("home-right__tab--active");

  //     // tab content
  //     if ([...this.classList].includes("home-right__tab-1")) {
  //       document.querySelector(".home-right__list-1").style.display = "none";
  //       document.querySelector(".home-right__list-2").style.display = "block";
  //     } else {
  //       document.querySelector(".home-right__list-1").style.display = "block";
  //       document.querySelector(".home-right__list-2").style.display = "none";
  //     }
  //   });

  //   // hover
  //   tab.addEventListener("mouseenter", function() {
  //     tab.querySelector(".tab-icon").style.display = "none";
  //     tab.querySelector(".tab-icon--active").style.display = "block";
  //     this.classList.add("home-right__tab--hover");
  //   });

  //   // un-hover
  //   tab.addEventListener("mouseleave", function() {
  //     const active = [...this.classList].includes("home-right__tab--active");
  //     this.classList.remove("home-right__tab--hover");
  //     if (!active) {
  //       tab.querySelector(".tab-icon").style.display = "block";
  //       tab.querySelector(".tab-icon--active").style.display = "none";
  //     }
  //   });
  // });

  // const openModal = () => {
  //   document.querySelector(".home-right__modal-root").style.display = "block";
  //   document.querySelector("#home-index").style.overflowY = "hidden";
  // };
  // const closeModal = () => {
  //   document.querySelector(".home-right__modal-root").style.display = "none";
  //   document.querySelector("#home-index").style.overflowY = "unset";
  // };
  // const body = document.querySelector(".home-right__body");
  // const footer = document.querySelector(".home-right__footer");
  // body.addEventListener("click", openModal);
  // footer.addEventListener("click", openModal);
  // const layer = document.querySelector(".home-right__modal-layer");
  // const closeIcon = document.querySelector(".home-right__modal-content .close");
  // layer.addEventListener("click", closeModal);
  // closeIcon.addEventListener("click", closeModal);

  const getRecommItems = () => {
    const $loading = $("#js-filter-loading");
    $loading.removeClass("is-hidden");
  };
  getRecommItems();

  $("#js-recommend-replace").on("click", () => {
    getRecommItems();
  });

  document.getElementById("js-daily-container") &&
    document
      .getElementById("js-daily-container")
      .addEventListener("wheel", e => {
        const panel = e.currentTarget;
        if (panel.scrollHeight <= panel.clientHeight) return;

        const mt = panel.scrollTop;
        const { deltaY } = e;
        const isEnd = panel.scrollHeight - mt === panel.clientHeight;
        if (deltaY > 0 && isEnd) e.preventDefault();
      });

  const textarea = document.querySelector(".home-ai__textarea textarea");

  textarea.addEventListener("input", () => {
    if (textarea.value) {
      document.querySelector(".home-ai__textarea .pass").style.display =
        "block";
      document.querySelector(".home-ai__textarea .disable").style.display =
        "none";
    } else {
      document.querySelector(".home-ai__textarea .pass").style.display = "none";
      document.querySelector(".home-ai__textarea .disable").style.display =
        "block";
    }
  });

  const consumeFn = (cb = () => {}) => {
    const v = sessionStorage.getItem("ai-textarea");
    if (v) {
      textarea.value = v;
      sessionStorage.removeItem("ai-textarea");
      cb();
    }
  };

  consumeFn(() => {
    document.querySelector(".home-ai__textarea .pass").style.display = "block";
    document.querySelector(".home-ai__textarea .disable").style.display =
      "none";
  });

  const commonFn = (requireV = true) => {
    const v = textarea.value;
    v && sessionStorage.setItem("ai-textarea", v);
    const text = $("#js-header-login")
      .eq(0)
      .text();
    if (text === "去登录") {
      $("#js-header-login").click();
    } else {
      if (requireV) {
        v && window.open("/open_chat");
      } else {
        window.open("/open_chat");
      }
    }
  };

  const logo = document.querySelector(".home-ai__title");
  logo.addEventListener("click", () => {
    commonFn(false);
  });

  const btn = document.querySelector(".home-ai__textarea .pass");
  btn.addEventListener("click", () => {
    commonFn();
  });

  // 监听回车键
  textarea.addEventListener("keydown", e => {
    // 判断是否按下回车键
    if (e.key === "Enter" || e.keyCode === 13) {
      // 如果同时按下 Shift 键，则允许换行
      if (e.shiftKey) {
        return; // 不做任何操作，允许换行
      } else {
        // 阻止默认行为，避免换行
        e.preventDefault();
        // 触发 btn 的点击事件
        btn.click();
      }
    }
  });
};

export default home;
